<template>
  <div class="home">
    <header>
      <m-page-header title="操作指南"></m-page-header>
    </header>
    <main>
      <div class="description">
        <ul>
          <li class="title">
            <h5>操作指南:</h5>
          </li>
          <li>1.新建积分商品:选择商品并给商品规格填写积分。</li>
          <li>2.发货设置中设置积分发放的规则。</li>
          <li>3.售卖积分明细展示积分商品相关数据。</li>
        </ul>
      </div>
      <div class="images">
        <m-image-preview :imgList="[homePreview1, homePreview2]" />
      </div>
    </main>
  </div>
</template>

<script>
import MPageHeader from '@/components/pageHeader/PageHeader.vue';
import MImagePreview from '@/components/imagePreview/ImagePreview.vue';

import homePreview1 from '../statics/img/home1.png';
import homePreview2 from '../statics/img/home2.png';
export default {
  name: 'Home',
  components: {
    'm-page-header': MPageHeader,
    'm-image-preview': MImagePreview,
  },
  setup() {
    return {
      homePreview1,
      homePreview2,
    };
  },
};
</script>

<style lang="scss" scoped>
main {
  .description {
    ul {
      padding: 20px;
      li {
        padding: 8px 0;
        .title {
          padding: 10px 0;
        }
      }
    }
  }
  .images {
    padding: 20px;
  }
}
</style>
